import React, { useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { auth } from 'firebase';
import { sendPasswordResetEmail } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { getErrorMessage } from "features/helper";
import './index.scss'

const ForgotPasswordForm = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };
  const onFinish = (values) => {
    setLoading(true)
    if (localStorage.getItem('i18nextLng') !== "en") {
      auth.languageCode = "zh-TW"
    }
    sendPasswordResetEmail(auth, values.email).then(() => {
      setLoading(false);
      openNotificationWithIcon('success', 'Success', t('passwordResetEmailSuccess'))
    }).catch((err) => {
      setLoading(false)
      console.log("pass: ", err)
      openNotificationWithIcon('error', 'Error', t(getErrorMessage(err.code)));
    })
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          {
            type: 'email',
            message: t('validEmailError'),
          },
          {
            required: true,
            message: t('emailRequiredError'),
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t('email')} />
      </Form.Item>
      <Form.Item>
        <Button 
          type="primary" 
          htmlType="submit" 
          className="login-form-button"
          loading={loading}
        >
          {t('resetPassword')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ForgotPasswordForm;