import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  globalHeaderText: '',
  currentlyPlayingMedia: '',
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    updateGlobalHeaderText: (state, action) => {
      state.globalHeaderText = action.payload;
    },
    updateCurrentlyPlayingMedia: (state, action) => {
      state.currentlyPlayingMedia = action.payload;
    }
  }
});

export const {
  updateGlobalHeaderText,
  updateCurrentlyPlayingMedia
} = uiSlice.actions
export default uiSlice.reducer