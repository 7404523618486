import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'DesignSystem/Button';
import { auth } from 'firebase';
import { sendEmailVerification } from "firebase/auth";
import { useTranslation } from 'react-i18next';
import './index.scss';
import { authorizeUser } from 'features/userSlice';

export default function VerifyEmail() {
  const { t } = useTranslation();
  const [verifiedEmailSent, setVerifiedEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user.userDetails);
  const dispatch = useDispatch();

  const handleEmailVerification = () => {
    setLoading(true);
    if (localStorage.getItem('i18nextLng') !== "en") {
      auth.languageCode = "zh-TW"
    }
    sendEmailVerification(auth.currentUser, null).then(() => {
      setVerifiedEmail(true);
      setLoading(false);
    }).catch((err) => {
      console.log("Error: ", err)
      setVerifiedEmail(true);
      setLoading(false);
    })
  }

  const updateUser = () => {
    if (auth.currentUser) {
      if (user) {
        if (!user.emailVerified) {
          auth.currentUser.getIdToken(true).then((token) => {
            dispatch(authorizeUser({ idToken: token }))
          })
        }
      }
    }
  }

  const getButton = () => {
    if (loading) {
      return (
        <Button loading={loading} text={t('verifyEmailButton')} type="tertiary-btn"/>
      )
    }
    return <Button onClick={() => handleEmailVerification()} text={t('verifyEmailButton')} type="tertiary-btn"/>
  }

  const getEmailVerification = () => {
    if (user) {
      if (auth.currentUser) {
        if (!auth.currentUser.emailVerified) {
          if (!verifiedEmailSent) {
            return (
              <div className="verify-email-container">
                <span className="content">{t('verifyText')}</span>
                <span className="action">{getButton()}</span>
              </div>
            )
          } else { 
            return (
              <div className="verify-email-container">
                <span className="content">{t('verifyEmailSent')}</span>
              </div>
            )
          }
        } else {
          updateUser()
          return <></>
        }
      }
    }
    return <></>
  }

  return getEmailVerification()
}
