import React from 'react'
import AuthenticationModal from 'domain/AuthenticationModal';
import Button from 'DesignSystem/Button';
import { useTranslation } from 'react-i18next';
import './index.scss';

export default function FooterAuthentication() {
  const { t } = useTranslation();
  return (
    <div className="footer-authentication-container">
      <div className="field content">
        {t('loginFollow')}
      </div>
      <div className="auth-btns-group">
        <div className="field">
          <AuthenticationModal initialAuthScreen={'login'} button={<Button type="primary-btn" text="Login" />} />
        </div>
        <div className="field">
          <AuthenticationModal initialAuthScreen={'signup'} button={<Button type="tertiary-btn" text="Sign up" />} />
        </div>
      </div>
    </div>
  )
}
