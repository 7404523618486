import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from './helper';
import { message } from 'antd';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    profileDetails: null,
    updatingProfile: 'idle',
    userAction: ''
  },
  reducers: {
    updateProfileDetails: (state, action) => {
      state.profileDetails = action.payload
    },
    resetProfileDetails: state => {
      state.profileDetails = null
    },
    updatingProfileStatus: (state, action) => {
      state.updatingProfile = action.payload
    },
    updatingUserAction: (state, action) => {
      state.userAction = action.payload
    }
  },
});

export const { resetProfileDetails,
               updateProfileDetails,
               updatingProfileStatus,
               updatingUserAction
              } = profileSlice.actions;

export const getProfile = (userName) => dispatch => {
  const token = localStorage.getItem('lumieConsoleToken');
  axios.get(`${API_URL}/v1/user/${userName}`, { headers: {"Authorization" : `Bearer ${token}`} })
  .then((resp) => {
    dispatch(updateProfileDetails(resp.data));
  }).catch((err) => {
    console.log("error: ", err.response.data.errors[0])
    dispatch(updatingProfileStatus('not-found'))
    message.error(err.response.data.errors[0]);
  })
}

export const updateProfile = (profileDetails) => dispatch => {
  const token = localStorage.getItem('lumieConsoleToken');
  dispatch(updatingProfileStatus('processing'));
  axios.patch(`${API_URL}/v1/user`, profileDetails, { headers: {"Authorization" : `Bearer ${token}`} })
  .then((resp) => {
    dispatch(updateProfileDetails(resp.data))
    dispatch(updatingProfileStatus('success'))
  }).catch((err) => {
    console.log("Error: ", err);
    dispatch(updatingProfileStatus('error'))
  })
}

export default profileSlice.reducer;