import React from 'react';
import './index.scss';

export default function GradientText({children}) {
  return (
    <span className="gradient-text">
      {children}
    </span>
  )
}
