import React from 'react';
import { Select } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import './index.scss';

const { Option } = Select;

export default function LanguageSwitcher(props) {
  const handleChange = (value) => {
    i18next.changeLanguage(value)
  }

  const getDefaultValue = () => {
    const langCodes = ["en", "zh"]
    if (langCodes.includes(localStorage.getItem('i18nextLng'))) {
      return localStorage.getItem('i18nextLng')
    } else {
      i18next.changeLanguage("zh")
      return "zh"
    }
  }
  const getDropdown = () => {
    if (props.settings) {
      return (
        <div style={{alignItems: 'center'}} className={`language-switcher-container primary-color`}>
          <Select style={{width: '50%', marginTop: '10%'}} defaultValue={getDefaultValue()} placeholder={<GlobalOutlined />} onChange={handleChange}>
            <Option value="zh">中文</Option>
            <Option value="en">English</Option>
          </Select>
        </div>
      )
    }
    return (
      <div className={`language-switcher-container primary-color`}>
        <Select defaultValue={getDefaultValue()} dropdownClassName={'language-dropdown'} bordered={false} placeholder={<GlobalOutlined />} onChange={handleChange}>
          <Option value="zh">中文</Option>
          <Option value="en">English</Option>
        </Select>
      </div>
    )
  }

  return (getDropdown())
}
