import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons'; 
import './index.scss';

export default function ProfilePhoto({ photoUrl, username, size = 100, ...rest }) {

  const getName = () => {
    if (username.length) {
      return username[0].toUpperCase()
    } else {
      return <UserOutlined />
    }
  }

  const getAvatar = () => {
    if (photoUrl.length > 0) {
      return (
        <Avatar
          src={photoUrl}
          size={{
            xs: size,
            sm: size,
            md: size,
            lg: size,
            xl: size,
            xxl: size,
          }}
          {...rest}
        />
      )
    } else {
      return (
        <Avatar
          size={{
            xs: size,
            sm: size,
            md: size,
            lg: size,
            xl: size,
            xxl: size,
          }}
          {...rest}
        >{getName()}</Avatar>
      )
    }
  }

  return (
    <div className="profile-photo-container">
      {getAvatar()}
    </div>
  )
}
