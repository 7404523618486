import React, { useState } from 'react';
import { Form, Input, Button, notification, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { auth } from 'firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import GradientText from 'DesignSystem/GradientText';
import { useTranslation } from "react-i18next";
import { getErrorMessage } from "features/helper";
import './index.scss'

const AuthenticationForm = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: 'Error',
      description:
        message,
    });
  };

  const getForgotPassword = () => {
    if (props.popup && props.popup === true) {
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ cursor: 'pointer' }} onClick={() => props.setActiveAuthScreen('forgotPassword')}><GradientText>{t('forgotPassword')}</GradientText></div>
        </div>
      )
    }
    if(props.page === 'login') {
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Link to="/forgot-password"><GradientText>{t('forgotPassword')}</GradientText></Link>
        </div>
      )
    }
  }
  const onFinish = (values) => {
    setLoading(true)
    if(props.page === 'signup') {
      createUserWithEmailAndPassword(auth, values.email, values.password).then((user) => {
        if (localStorage.getItem('i18nextLng') !== "en") {
          auth.languageCode = "zh-TW"
        }
        sendEmailVerification(user.user, null);
        setLoading(false);
        form.resetFields();
      }).catch((err) => {
        console.log("Error: ", err);
        setLoading(false);
        openNotificationWithIcon('error', t(getErrorMessage(err.code)))
      })
    } else {
      signInWithEmailAndPassword(auth, values.email, values.password).then(() => {
        setLoading(false);
        form.resetFields();
      }).catch((err) => {
        setLoading(false);
        openNotificationWithIcon('error', t(getErrorMessage(err.code)))
      })
    }
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      form={form}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          {
            type: 'email',
            message: t('validEmailError'),
          },
          {
            required: true,
            message: t('emailRequiredError'),
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t('email')} />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: t('passwordRequiredError'),
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder={t('password')}
        />
      </Form.Item>
      <Form.Item>
        {getForgotPassword()}
      </Form.Item>
      <Form.Item>
        <Button 
          type="primary" 
          htmlType="submit" 
          className="login-form-button"
          loading={loading}
        >
          {props.page === 'signup' ? t('signup') : t('signin')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AuthenticationForm;