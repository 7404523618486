export const TRANSLATIONS_EN = {
  // Auth Section
  signin:"Log In",
  signup:"Sign up",
  email: "Email",
  password: "Password",
  forgotPassword: "Forgot Password",
  signupMessage: "Don't have an account?",
  loginMessage: "Already have an account?",
  validEmailError: "The input is not valid E-mail!",
  emailRequiredError: "Please input your E-mail!",
  passwordRequiredError: "Please input your Password!",
  backTo: 'Back To',
  resetPassword: "Reset Password",

  // Sidebar Section
  home: "Home",
  myProfile: "My Profile",
  earnings: "Earnings",
  purchases: "Purchases",
  settings: "Settings",

  // Post
  newPost: "New Post",
  createPost: "New Post",
  titleLabel: "Title (Required)",
  titlePlaceHolder: "Enter Post Title...",
  describeLabel: "Describe your post",
  describePlaceHolder: "Enter about your post here...",
  createButton: "Create Post",
  paidPostText: "Click to make your post paid",
  freePostText: "Click to make your post free",
  paidActionText: "Paid Post",
  freeActionText: "Free Post",
  amountLabel: "How much does it cost?",
  editPost: "Edit Post",
  editButton: "Save",
  addMedia: "Add media to Post",
  unSavedChanges: "Unsaved Changes",
  continueEditing: "Continue Editing",
  leave: "Leave",
  leavewarningtext: "The changes will be lost. Are you sure you want to leave?",
  pause: "Pause",
  play: "Play",
  removeMedia: "Remove Media. You will have to refresh the page to get the original media",
  videoProcessing: "Video is still Processing in background, your followers cannot see this post unless the video has processed completely.",
  editSuccess: "Post Edited Successfully",
  uploadError: "Error: Could not upload file",
  deleteAttachmentError: "Error: Could not delete attachment",
  editPostError: "Error: Could not edit post",
  createPostError: "Error: Could not create post",
  amountError: "Amount should be between NT$ 30 to NT$ 15000",
  usdAmountError: "Amount should be between $1 to $500",
  deletePostWarning: "Posts that are deleted will not be viewable for anyone including you. Please click on 'Delete' to confirm deletion",
  addTag: 'Add Tag',
  tagLabel: 'Tags (max 20)',
  likedPostList: 'People who liked the post',
  purchasedPostList: 'People who purchased the post',
  //Settings
  account: "Account",
  termsAndPolicies: "Terms & Policies",
  logout: "Log Out",
  accountInfo: "Account Info",
  username: "Username",
  changeEmail: "Change Email",
  changePassword: "Change Password",
  changeOrResetPassword: "Change or reset Password",
  currentPassword: "Current Password",
  confirmPassword: "Confirm Password",
  passwordsNotMatchError: 'The two passwords that you entered do not match!',
  confirmPasswordRequiredError: "Please confirm your password!",
  submit: "Submit",
  invalidCredentials: "Invalid credentials",
  emailUpdated: "Email Updated Successfully",
  authenticateError: "Some error occured, please authenticate again",
  newEmailInput: "Please input your New Email",
  newEmailLabel: "New Email",
  passwordUpdated: "Password Updated Successfully",
  passwordRequired: "Password is required",
  newPassword: "New Password",
  deleteAccount: "Delete Account",
  deleteYourAccount: "Delete Your Account",
  cancel: "Cancel",
  accountDeleteSuccess: "Account Deleted Successfully",
  accountDeletion: "account deletion",
  reentercurrentpassword: "Please re-enter your current password to confirm",
  reminder: "REMINDER:ONCE ACCOUNT IS DELETED, WE WILL NOT BE ABLE TO RESTORE IT.",
  deleteConfirm: "Are you sure you want to delete your account?",
  deleteExplain: "After account deletion, all your information and content will no longer be viewable on the platform.",
  removeAccount: "You need to send an email request to <linktag>support@94hive.cc</linktag> for account deletion.",
  deleteReason: "The reason for which you are deleting your account",
  deleteListHeader: "Please include the following information in your email:",
  logOutText: "Are you sure you want to log out of 94Hive?",
  myCards: "My Cards",
  payment: "Payment",
  deleteCard: "Delete",
  defaultCard: "Default",
  deleteCardWarningTitle: 'Delete Card',
  deleteCardWarning: 'Are you sure you want to delete this card?',
  addedCardSuccess: 'Added Card Successfully',
  deleteCardSuccess: 'Deleted Card Successfully',
  addNewCard: 'Add New Card',
  addCard: 'Add Card',
  defaultCardSuccess: "Default card changed successfully",
  notifications: 'Notifications',
  notificationsDescription: 'Get notified when your favourite creator posted on 94Hive.',
  emailNotification: 'Email Notification',
  emailNotifications: 'Email Notifications',
  emailNotificationDescription: "Get email notifications to find out what's going on 94Hive.",
  relatedToYou: "Related to you & your posts",
  weeklySummary: 'Weekly Summary',
  fromLumi: 'From 94Hive',
  whenAway: 'Top posts when you are away',
  //earnings and purchases
  earningsHistory: "Earnings History",
  postUrl: "Post URL",
  amount: "Amount",
  currency: "Currency",
  symbol: "Symbol",
  transactionId: "Transaction ID",
  orderId: "Order ID",
  transactionDate: "Transaction Date",
  totalEarnings: "Total Earnings",
  totalEarningsDescription: "Total Earnings without any fees or tax deductions",
  netEarnings: "Net Earnings",
  netEarningsDescription: "Net Earnings after fees/tax deductions",
  remainingBalance: "Remaining Balance",
  remainingBalanceDescription: "Remaining balance after payouts",
  payouts: "Payouts",
  purchaseHistory: "Purchase History",
  selectPaymentGateway: "Select Payment Gateway",
  changeLanguage: 'Change Language',

  // My Profile
  post: "Post",
  posts: "Posts",
  editProfile: "Edit Profile",
  followers: "Followers",
  following: "Following",
  noFollowers: "No followers found",
  noFollowing: "You aren’t following anyone yet.",
  profileNotFound: "Profile not found, please check the username and try again",
  subscribe: "Subscribe",
  unsubscribe: "Unsubscribe",
  postDeleteSuccess: "Post deleted successfully",
  loadMore: "Load More",
  deletePost: "Delete Post",
  disabledEdit: "You can't edit a post that has been purchased before",
  disabledDelete: "You can't delete a post that has been purchased before",
  save: "Save",
  usernameAvailable: "Username is available",
  usernameUnAvailable: "Username is already taken",
  profileUrl: "Username (URL)",
  displayName: "Display Name",
  aboutYou: "About You",
  website: "Website",
  name: "Name",
  displayNameRequired: "Display Name is required",
  usernameRequired: "Username is required",
  usernameMaxLenError: 'Username should be between 2 to 24 characters long',
  nameError: "Name should be between 2 to 40 characters long",
  aboutYouError: "You have exceeded the characters limit",
  error: "Something went wrong. Please try again",
  passwordResetEmailSuccess: 'Please check your inbox for password reset',
  verifyText: "Please verify your email address",
  verifyEmailButton: "Verify",
  verifyEmailSent: "Please check your email and click on the link to verify your email, then refresh the current page",
  usernameRegexError: "Username can only contain letters, numbers, and '_'",

  // auth errors
  wrongPassword: 'Incorrect Password. Please check your password again',
  weakPassword: 'Password should be at least 6 characters',
  emailNotFound: 'Email not found. Please check email address or Signup',
  emailExists: 'Email already in use. Please Login',
  authenticateAgain: 'Please authenticate again.',

  // Payout
  payoutEmailSubject: "94Hive request for a payout (Your user id is",
  payoutLineOne: "To request for a payout, please follow the following steps",
  payoutLineTwo: "Email your payout request to support@94hive.cc. Please include the following information in your email:",
  payoutLineThree: "UserID (Your user id is",
  payoutLineFour: "Amount to pay in TWD (minimum TWD 1000 per request)",
  payoutLineFive: "Recipient's Name",
  payoutLineSix: "Contact Phone Number",
  payoutLineSeven: "ID Number",
  payoutLineEight: "Bank Code for Recipient's Account",
  payoutLineNine: "Branch Information for Recipient's Account",
  payoutLineTen: "Recipient's Account Number",
  payoutLineEleven: "You should receive an acknowledgement email from our team within 3 working days",
  payoutLineTwelve: "Payout request received before the 15th of each month and approved by our team will be processed by the end of the calendar month",
  payout: "Payout",

  // Unlock
  unlockMessage: "<paragraph>You are about to purchase <creatortag>{{username}}</creatortag> content for <amounttag>{{amount}}</amounttag>. Are you sure want to continue?</paragraph>",
  unlockHeader: "Unlock Content",
  unlockbtn: "{{amount}} to unlock media",
  confirm: "Confirm",

  // Terms
  termsPolicies: "By signing up, you agree to 94Hive's <linktag>Terms of Service</linktag> and <privacyTag>Privacy Policy</privacyTag>",
  readTerms: "Read about our terms of use",
  terms: "Terms of Use",
  loginFollow: "Follow your favourite content creator on 94Hive by creating or login into your account",
  emptyFeed: "There's nothing on your feed yet. Follow creators to see more content.",
  zeroStatePurchaseHistory: "No Purchase History. Please purchase and support other creators.",
  zeroStateEarningHistory: "No Earning History",

  //stripe
  payWith:"Pay With",
  saveCard:"Save Card",
  backToSavedCards: "Back to saved cards",
  secureCheckout: "Secure Checkout",
  paymentDetails: "Payment Details",
  addNewPayment: "Add New Payment Method",
  total: "Total",
  endsWith: "ends with",
  pay: "Pay",

  //Verification
  resendLink: "Resend Link",
  tryAgain: "try again in",
  backToLogin: "Back To Login",
  accountVerification: "Account Verification",
  verificationText: "We have sent you a verification link!",
  verificationDescription: "To make sure this email belongs to you, please verify through the verification link we've sent to <strongtag>{{email}}</strongtag>",
  verificationErrorText: "Too many Email Verification Requests.",
  verificationErrorDescription: "Please try again in sometime",
  tooManyRequestError: "Too many Requests.Please try again in sometime",

  //Comments
  deleteComment: "Delete Comment",
  reportComment: "Report Comment",
  reportwarningtext: "Thanks for looking out for the community. Let us know what's happening and the team will look into it shortly.",
  continueReport: "Continue",
  cancelReport: "Cancel",
  reportIssueLabel: "What is the issue?",
  reportThanks: "Thanks for keeping the community safe.",
  reportSuccessful: "The 94Hive team will be reviewing this shortly and have an update on this matter soon.",
  done: "Done",

  //reportCommentOptions
  spam: "Spam",
  hateSpeech: "Hate speech or symbols",
  violence: "Violence or dangerous organisations",
  illegal: "Sale of illegal or regulated goods",
  bully: "Bullying or harassment",
  ipviolation: "Intellectual property violation",
  falseInfo: "False information",
  suicide: "Suicide, self-injury or eating disorders",
  dontLike: "I just don't like it",
  others: "Others",
 };