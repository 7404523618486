import React from 'react';
import { Link } from 'react-router-dom';
import ProfilePhoto from 'components/ProfilePhoto';
import subscribedIcon from 'resources/images/subscribedIcon.png';
// import UserInfo from 'components/UserInfo';
import './index.scss';

const UserList = ({users, handleUserClick}) => {

  // const getFollowing = (user) => {
  //   if (user.subscriberId.length > 0) {
  //     return true
  //   }

  //   return false
  // }

  const getUsers = () => {
    if (users.length > 0) {
      const userList = users.map((user) => {
        return (
          <Link key={user.username} onClick={() => handleUserClick(user)} to={`/${user.username}`}>
            <div className="user-container">
              <div className="user-profile-avatar">
                <ProfilePhoto
                  photoUrl={user.photoUrl}
                  username={user.username}
                  size={34}
                  style={{ fontSize: '14px' }}
                />
              </div>
              <div className="name-container">
                <div className="username">{user.username}</div>
                <div className="extra-info-container">
                  <div className="name">{user.name}</div>
                  <div className="subscriber">{user.subscriberId.length > 0 ? <img src={subscribedIcon} alt="subscribed Icon" className="subscribed-icon" /> : <></>}</div>
                </div>
              </div>
            </div>
          </Link>
        )
      })
      return userList
    }
  }
  return (
    <div className="user-list-container">
      {getUsers()}
    </div>
  )
}

export default UserList;
