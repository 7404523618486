import React from 'react'
import { useNavigate } from "react-router-dom";
import AuthenticationForm from 'domain/Authentication/Form';
import GradientText from 'DesignSystem/GradientText';
import { Trans, useTranslation } from "react-i18next";
import LumieLogo from 'resources/images/94hive-vertical-logo.png';
import { auth } from 'firebase';
import { GoogleAuthProvider, signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import { FcGoogle } from 'react-icons/fc';
import { FacebookFilled } from '@ant-design/icons';
import './index.scss'

function SignUp(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getRedirectionBlock = () => {
    if (props.popup) {
      return (
        <div className="redirection">
          {t('loginMessage')} <span onClick={() => {props.setActiveAuthScreen('login')}}><GradientText>{t('signin')}</GradientText></span>
        </div>
      )
    } else {
      return (
        <div className="redirection">
          {t('loginMessage')} <span onClick={() => {navigate('/login')}}><GradientText>{t('signin')}</GradientText></span>
        </div>
      )
    }
  }

  const getLogo = () => {
    if (!props.popup) {
      return <img src={LumieLogo} alt="lumie-logo" />
    }
  }

  const signInWithSocialMedia = (selectedProvider) => {
    let provider;
    if (selectedProvider === 'google') {
      provider = new GoogleAuthProvider();
    } else {
      provider = new FacebookAuthProvider();
    }
    if (localStorage.getItem('i18nextLng') !== "en") {
      auth.languageCode = "zh-TW"
    }
    signInWithPopup(auth, provider)
    .then((resp) => {
      console.log("Success");
    }).catch((err) => {
      console.log("Err: ", err);
    })
  }

  return (
    <div className="authentication-container">
      <div className="header">
        {getLogo()}
        {t('signup')}
      </div>
      <div className="social-login-container">
        <div className="social-btn-container">
          <button onClick={() => signInWithSocialMedia('google')} className="google-btn"><FcGoogle /> Sign up with Google</button>
        </div>
        <div className="social-btn-container" style={{display:'none'}}>
          <button onClick={() => signInWithSocialMedia('facebook')} className="facebook-btn">
            <FacebookFilled 
              style={{color: "#1778F2", 
                      fontSize: "1.4em", 
                      marginRight: '8px', 
                      verticalAlign: '-4px'}} /> Sign in with Facebook
          </button>
        </div>
      </div>
      <div className="separation-container" style={{ display: 'none' }}>
        <div className="horizontal-bar" /><span>or</span><div className="horizontal-bar" />
      </div>
      <AuthenticationForm page={'signup'} />
      <div className="terms-policies-container">
        <Trans
          i18nKey="termsPolicies"
          components={{ linktag: <a href="https://www.94hive.cc/terms.html" target="_blank" rel="noreferrer" className="link" />, privacyTag: <a href="https://www.94hive.cc/privacy-policy.html" target="_blank" rel="noreferrer" className="link" /> }}
        />
      </div>
      {getRedirectionBlock()}
    </div>
  )
}

export default SignUp;
