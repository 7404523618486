import React from 'react';
import { Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

export default function Loader({size}) {

  const antIcon = <Loading3QuartersOutlined style={{ fontSize: size || 24, color: '#FFBC0F' }} spin />;
  return (
    <>
      <Spin indicator={antIcon} />
    </>
  )
}
