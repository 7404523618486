import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './index.scss';

export default function SidebarOption({text, path, parentPath, Icon, ...rest}) {
  let location = useLocation();
  const allClassNames = location.pathname.includes(parentPath) && path.length > 0 ? "sidebarOption activeSidebarOption" : "sidebarOption sidebarOptionHover"
  return (
    <div className={allClassNames}>
      <Link {...rest} className="sidebar-link" to={path}>
        <>
          <Icon />
          <div className="link-content">{text}</div>
        </>
      </Link>
    </div>
  )
}
