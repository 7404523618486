import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './index.scss';

export default function BottomBarOption({path, parentPath, Icon, ...rest}) {
  let location = useLocation();
  const allClassNames = location.pathname.includes(parentPath) && path.length > 0 ? "bottombarOption activeBottombarOption" : "bottombarOption"
  return (
    <div className={allClassNames}>
      <Link {...rest} className="bottombar-link" to={path}>
        <>
          <Icon />
        </>
      </Link>
    </div>
  )
}
