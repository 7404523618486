export const TRANSLATIONS_ZH = {
  // Auth Section
  signin:"登錄",
  signup:"註冊",
  email: "電子郵件",
  password: "密碼",
  forgotPassword: "忘記密碼",
  signupMessage: "尚未建立帳號？",
  loginMessage: "已註冊帳號？",
  validEmailError: "錯誤的電子郵件地址資訊",
  emailRequiredError: "請輸入您的電子信箱",
  passwordRequiredError: "請輸入您的密碼",
  backTo: '返回',
  resetPassword: "重置密碼",

  // Sidebar Section
  home: "首頁",
  myProfile: "個人檔案",
  earnings: "收入與兌現",
  purchases: "已購買",
  settings: "設定",

  // Post
  post: "貼文",
  newPost: "新貼文",
  createPost: "建立新貼文",
  titleLabel: "標題",
  titlePlaceHolder: "請輸入貼文標題",
  describeLabel: "關於你的發佈",
  describePlaceHolder: "請輸入關於貼文的描述",
  createButton: "建立貼文",
  paidPostText: "將貼文設定為付費",
  freePostText: "將貼文設定為免費",
  paidActionText: "付費貼文",
  freeActionText: "免費貼文",
  amountLabel: "需要支付多少金額？",
  editPost: "編輯",
  editButton: "儲存",
  addMedia: "上傳內容",
  unSavedChanges: "未儲存變更",
  continueEditing: "繼續編輯",
  leave: "放棄",
  leavewarningtext: "仍有尚未儲存的內容，您確定要放棄？",
  pause: "暫停",
  play: "播放",
  removeMedia: "移除已上傳媒體，您會需要重整頁面",
  videoProcessing: "影片仍在背景上傳中，你的追蹤者將無法看到貼文直到影片上傳完成",
  editSuccess: "貼文已成功編輯",
  uploadError: "出現錯誤！無法上傳檔案",
  deleteAttachmentError: "出現錯誤！無法刪除附檔",
  editPostError: "出現錯誤：無法編輯貼文",
  createPostError: "出現錯誤：無法創建貼文",
  amountError: "金額設定不能低於NT$30，不得超過NT$15,000",
  usdAmountError: "金額設定不能低於$1，不得超過$500",
  deletePostWarning: "刪除的貼文將無法在被任何人觀看，包含您自己。請點選刪除確認執行",
  addTag: '新增標籤',
  tagLabel: '標籤(上限20個)',
  likedPostList: '誰給了愛心',
  purchasedPostList: '誰購買了貼文',

  //Settings
  account: "帳戶",
  termsAndPolicies: "條款＆政策",
  logout: "登出",
  accountInfo: "帳號資訊",
  username: "帳號名稱",
  changeEmail: "更改電子郵件",
  changePassword: "更改密碼",
  changeOrResetPassword: "改變或重設密碼",
  currentPassword: "目前密碼",
  confirmPassword: "確認密碼",
  passwordsNotMatchError: "密碼不符，請再次確認您所輸入的兩欄密碼是正確且一致的。",
  confirmPasswordRequiredError: "請確認您的密碼",
  submit: "送出",
  invalidCredentials: "無法辨識的證件",
  emailUpdated: "電子郵件已成功更新",
  authenticateError: "出現錯誤，請重新驗證",
  newEmailInput: "請輸入您的新電子郵件",
  newEmailLabel: "新電子郵件",
  passwordUpdated: "密碼已成功更新",
  passwordRequired: "需要輸入密碼",
  newPassword: "新密碼",
  myCards: "我的信用卡",
  payment: "付款",
  deleteCard: "刪除",
  defaultCard: "預設",
  deleteCardWarningTitle: '移除信用卡',
  deleteCardWarning: '您確定要移除這張信用卡嗎?',
  addedCardSuccess: '成功加入信用卡',
  deleteCardSuccess: '成功刪除信用卡',
  addNewCard: '加入新信用卡',
  addCard: '加入卡片',
  defaultCardSuccess: "預設信用卡已成功變更",
  changeLanguage: '更換語言',
  notifications: '提醒通知',
  notificationsDescription: '當你喜愛的創作者發文，你會在第一時間收到通知.',
  emailNotification: ' 電郵通知',
  emailNotifications: ' 電郵通知',
  emailNotificationDescription: "開啟電郵訂閱，讓你不錯過94Hive精彩的內容！",
  relatedToYou: "你的迴響&你的貼文",
  weeklySummary: ' 94Hive的一週回顧',
  fromLumi: 'From 94Hive',
  whenAway: '當你不在94Hive時，迴響最熱烈的貼文排行',

  //earnings and purchases
  earningsHistory: "所得紀錄",
  postUrl: "貼文連結",
  amount: "帳號",
  currency: "TWD",
  symbol: "NT$",
  transactionId: "出款編號",
  orderId: "流水編號",
  transactionDate: "請款日期",
  totalEarnings: "總所得",
  totalEarningsDescription: "扣除手續費與稅 總所得",
  netEarnings: "淨所得",
  netEarningsDescription: "扣除手續費與稅 淨所得",
  remainingBalance: "帳戶餘額",
  remainingBalanceDescription: "兌現後帳戶餘額",
  payouts: "兌現",
  purchaseHistory: "已購清單",
  selectPaymentGateway: "選擇付款方式",

  // My Profile
  posts: "貼文",
  editProfile: "編輯檔案",
  followers: "粉絲",
  following: "追蹤",
  noFollowers: "你還沒有任何跟隨者",
  noFollowing: "你尚未跟隨任何人",
  profileNotFound: "找不到檔案，請確認使用者名稱並重新查詢。",
  subscribe: "訂閱",
  unsubscribe: "取消訂閱",
  postDeleteSuccess: "已成功刪除貼文",
  loadMore: "更多",
  deletePost: "刪除",
  disabledEdit: "您無法編輯已被解鎖的貼文",
  disabledDelete: "您無法刪除已被解鎖的貼文",
  save: "儲存",
  usernameAvailable: "沒有重複，可以創建帳號",
  usernameUnAvailable: "用戶名已被使用",
  profileUrl: "用戶名 (URL)",
  displayName: "顯示名稱",
  aboutYou: "關於您",
  website: "網站",
  name: "Name",
  displayNameRequired: "需要（填寫 顯示名稱",
  usernameRequired: "需要（填寫）用戶名",
  usernameMaxLenError: '用戶名長度應介於2到24字元之間',
  nameError: "名称长度应介于 2 到 40 个字符之间",
  aboutYouError: "最大长度应介于 0 到 1000 个字符之间",
  error: "出現錯誤，請再試一次。",
  passwordResetEmailSuccess: '請檢查您的信箱以重置密碼',
  verifyText: "請驗證您的電子郵件",
  verifyEmailButton: "驗證",
  verifyEmailSent: "請檢查您的信箱，並透過信件連結點擊驗證，並重整頁面。",
  usernameRegexError: "用戶名稱只能使用文字/數字/底線",

  // Delete
  deleteAccount: "刪除帳號",
  deleteYourAccount: "刪除您的帳號",
  cancel: "取消",
  accountDeleteSuccess: "帳號刪除成功",
  accountDeletion: "帳號刪除",
  reentercurrentpassword: "請再次輸入密碼確認",
  reminder: "請留意：「一但帳號被刪除，我們將無法回復帳號。」",
  deleteConfirm: "請問您確認刪除您的帳號嗎？",
  deleteExplain: "您即將刪除您的94Hive帳號。在刪除帳號以後，您的所有資訊與發佈內容將永久無法在此平台上被閱覽。",
  removeAccount: "您會需要發送電子郵件至<linktag>support@94hive.cc</linktag>提出您的帳號刪除需求",
  deleteReason: "請說明您刪除帳號的理由",
  deleteListHeader: "請在刪除帳號的電子信中提供以下的資訊:",
  logOutText: "您確定要登出 94Hive 嗎？",

  //auth errors
  wrongPassword: '密碼錯誤，請再次確認您的密碼。',
  weakPassword: '密碼必須至少6個字元',
  emailNotFound: '找尋不到電子信箱，請再次確認註冊的電子信箱地址。',
  emailExists: '此電子信箱已經被註冊，請登入以繼續使用。',
  authenticateAgain: '請再次確認授權',

  //Payout
  payoutEmailSubject: "94Hive 出金申請（帳號ID:",
  payoutLineOne: "為了領出現金，請依照下列流程完成程序，我們會經過3個步驟。",
  payoutLineTwo: "請使用電子郵件送出您的需求到 support@94hive.cc 的信箱，並確認包含以下的內容",
  payoutLineThree: "帳號ID (您的帳號 ID 是",
  payoutLineFour: "您的領出金額最低下限為1000元新臺幣",
  payoutLineFive: "收款人姓名",
  payoutLineSix: "聯絡電話",
  payoutLineSeven: "身分證字號",
  payoutLineEight: "收款帳號銀行代碼",
  payoutLineNine: "收款帳號分行資訊",
  payoutLineTen: "收款帳號",
  payoutLineEleven: "您會在三個工作天內收到我們的回覆確認信件",
  payoutLineTwelve: "在每個月15號以前我們將會接受申請，我們會在每個月的最後一個工作天進行經過審核後匯款",
  payout: "兌現",

  // Unlock
  unlockMessage: "您即將付費<amounttag>{{amount}}</amounttag> 新臺幣購買 <creatortag>{{username}}</creatortag> 的貼文解鎖，請問您確定要繼續嗎？",
  unlockHeader: "解鎖內容",
  unlockbtn: "付費{{amount}}新臺幣解鎖貼文",
  confirm: "確認",

  //etc.
  loginFollow: "登入94Hive或創建帳號，以追蹤你喜歡的創作者",
  emptyFeed: "目前沒有更新資訊，請追蹤創作者可以看見更多內容",
  termsPolicies: "您必須同意94Hive's <linktag>Terms of Service</linktag> 和 <privacyTag>Privacy Policy</privacyTag>，便可註冊登入",
  readTerms: "請閱讀我們的使用條款",
  terms: "使用條款",
  zeroStatePurchaseHistory: "目前尚未有購買項目，若行有餘力也請記得付費解鎖，支持你喜歡的創作者呦！",
  zeroStateEarningHistory: "沒有收入紀錄",

  //stripe
  payWith:"選擇支付",
  saveCard:"儲存信用卡",
  backToSavedCards: "選擇預設信用卡",
  secureCheckout: "已加密支付",
  paymentDetails: "付款資訊",
  addNewPayment: "增加新付款方式",
  total: "總計",
  endsWith: "信用卡末四碼",
  pay: "支付",

  //Verification
  resendLink: "重新發送連結",
  tryAgain: "再試一次",
  backToLogin: "返回登入",
  accountVerification: "帳號驗證",
  verificationText: "我們已經送出驗證連結!",
  verificationDescription: "請確認這個是您的電子信箱，並透過 <strongtag>{{email}}</strongtag> 連結完成驗證", 
  verificationErrorText: "您已經寄送太多次驗證申請.",
  verificationErrorDescription: "請稍後再嘗試。",
  tooManyRequestError: "您已經寄送太多次驗證申請, 請稍後再嘗試。",

  //Comments
  deleteComment: "刪除評論",
  reportComment: "回報評論",
  reportwarningtext: "感謝您關注我們的社群。請讓我們知道發生什麼狀況,我們將盡快處理",
  continueReport: "繼續",
  cancelReport: "取消",
  reportIssueLabel: "請問是什麼問題?",
  reportThanks: " 感謝您維護社群安全",
  reportSuccessful: "94Hive團隊將對此進行審查並盡快更新狀況.",
  done: "完成",

  //reportCommentOptions
  spam: "垃圾郵件",
  hateSpeech: "討厭的言論或符號",
  violence: "暴力或危險的組織",
  illegal: "銷售違法或管制的商品",
  bully: "欺凌或騷擾",
  ipviolation: "侵犯智慧權",
  falseInfo: "錯誤資訊",
  suicide: "自殺、自殘或進食障礙",
  dontLike: "我只是不喜歡它",
  others: "其他",
};
