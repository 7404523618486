import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from './helper';
import { auth } from 'firebase.js';
import { signOut } from "firebase/auth";
import { message } from 'antd';
import { updateProfileDetails } from 'features/profileSlice';
import { removePosts } from 'features/homeFeedSlice';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userDetails: null,
    updatingUserProfile: 'idle',
    userAction: '',
    followers: null,
    following: null,
    userSearchHistory: [],
    recentSearchActionStatus: false,
  },
  reducers: {
    updateUserDetails: (state, action) => {
      state.userDetails = action.payload
    },
    userSignOut: state => {
      state.userDetails = null
    },
    updatingUserProfileStatus: (state, action) => {
      state.updatingUserProfile = action.payload
    },
    updatingUserAction: (state, action) => {
      state.userAction = action.payload
    },
    updateFollowers: (state, action) => {
      state.followers = action.payload;
    },
    updateFollowing: (state, action) => {
      state.following = action.payload;
    },
    updateUserSearchHistory: (state, action) => {
      state.userSearchHistory = action.payload;
    }
  },
});

export const { userSignOut,
               updateUserDetails,
               updatingUserProfileStatus,
               updatingUserAction,
               updateFollowers,
               updateFollowing,
               updateUserSearchHistory
              } = userSlice.actions;

export const authorizeUser = (userDetails) => dispatch => {
  axios.post(`${API_URL}/v1/auth`, userDetails).then((response) => {
    localStorage.setItem('lumieConsoleToken', response.data.token)
    localStorage.setItem('lumieConsoleTokenExpire', response.data.expire)
    dispatch(getUserDetails())
  }, (error) => {
    console.log("Error: ", error);
  });
}

export const getUserDetails = () => dispatch => {
  const token = localStorage.getItem('lumieConsoleToken');
  axios.get(`${API_URL}/v1/user`, { headers: {"Authorization" : `Bearer ${token}`} })
  .then((resp) => {
    dispatch(updatingUserProfileStatus('idle'))
    dispatch(updateUserDetails(resp.data));
    dispatch(getUserSearchHistory());
  }).catch((err) => {
    dispatch(logOut());
  })
}

export const getUserSearchHistory = () => dispatch => {
  const token = localStorage.getItem('lumieConsoleToken');
  axios.get(`${API_URL}/v1/search-history`, { headers: {"Authorization" : `Bearer ${token}`} })
  .then((resp) => {
    dispatch(updateUserSearchHistory(resp.data.history))
  }).catch((err) => {
    dispatch(logOut());
    console.log("Error fetching user search history: ", err);
  })
}

export const addUserSearchHistory = (searchDetails) => dispatch => {
  const token = localStorage.getItem('lumieConsoleToken');
  console.log("SearchDetails: ", searchDetails);
  axios.post(`${API_URL}/v1/search-history`, searchDetails, {headers: {"Authorization" : `Bearer ${token}`}})
  .then((resp) => {
    console.log("History Resp: ", resp);
    dispatch(updateUserSearchHistory(resp.data.history))
  }).catch((err) => {
    console.log("Error: ", err)
  })
}

export const deleteAllUserSearchHistory = () => dispatch => {
  const token = localStorage.getItem('lumieConsoleToken');
  axios.delete(`${API_URL}/v1/search-history?all=true`, {headers: { "Authorization" : `Bearer ${token}` }})
  .then((resp) => {
    dispatch(updateUserSearchHistory([]))
  }).catch((err) => {
    console.log("Error: ", err)
    message.error('Error in deleting all search history');
  })
}

export const deleteSearch = (deleteUsername) => dispatch => {
  const token = localStorage.getItem('lumieConsoleToken');
  axios.delete(`${API_URL}/v1/search-history/${deleteUsername}`, {headers: { "Authorization" : `Bearer ${token}` }})
  .then((resp) => {
    dispatch(updateUserSearchHistory(resp.data.history))
  }).catch((err) => {
    console.log("Error: ", err)
    message.error('Error in deleting');
  })
}

export const getPublicUserProfile = (userName) => dispatch => {
  axios.get(`${API_URL}/v1/user/${userName}`)
  .then((resp) => {
    dispatch(updateUserDetails(resp.data));
  }).catch((err) => {
    console.log("error: ", err.response.data.errors[0])
    dispatch(updatingUserProfileStatus('not-found'))
    message.error(err.response.data.errors[0]);
  })
}

export const updateProfile = (profileDetails) => (dispatch, getState) => {
  const token = localStorage.getItem('lumieConsoleToken');
  dispatch(updatingUserProfileStatus('processing'));
  axios.patch(`${API_URL}/v1/user`, profileDetails, { headers: {"Authorization" : `Bearer ${token}`} })
  .then((resp) => {
    if (getState().profile.profileDetails && getState().user.userDetails) {
      if(getState().profile.profileDetails.userId === getState().user.userDetails.userId) {
        dispatch(updateProfileDetails(resp.data));
      }
    }
    dispatch(updateUserDetails(resp.data))
    dispatch(updatingUserProfileStatus('success'))
  }).catch((err) => {
    console.log("Error: ", err);
    dispatch(updatingUserProfileStatus('error'))
  })
}

export const logOut = () => dispatch => {
  localStorage.removeItem('lumieConsoleToken')
  localStorage.removeItem('lumieConsoleTokenExpire')
  signOut(auth).then(() => {
    dispatch(userSignOut())
    dispatch(updatingUserAction('logout'))
    dispatch(updatingUserProfileStatus('idle'))
    dispatch(removePosts())
  }).catch((error) => {
    message.error('Error in Logging out');
  })
}

export const getFollowers = (userName) => dispatch => {
  axios.get(`${API_URL}/v1/user/${userName}/followers`)
  .then((resp) => {
    dispatch(updateFollowers(resp.data.users))
  }).catch((err) => {
    console.log(err)
  })
}

export const getFollowing = (userName) => dispatch => {
  axios.get(`${API_URL}/v1/user/${userName}/following`)
  .then((resp) => {
    dispatch(updateFollowing(resp.data.users))
  }).catch((err) => {
    console.log(err)
  })
}

export default userSlice.reducer;