import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { API_URL } from './helper';
import { message } from 'antd';

const initialState = {
  posts: null,
  postIds: [],
  page: 1,
  totalPostCount: 0
}

const homeFeedSlice = createSlice({
  name: 'homefeed',
  initialState,
  reducers: {
    updatePosts: (state, action) => {
      state.posts = {
        ...action.payload.posts
      }
      state.postIds = [...action.payload.postIds]
      state.totalPostCount = action.payload.totalPostCount ? action.payload.totalPostCount : state.totalPostCount;
    },
    nextPage: state => {
      state.page = state.page + 1
    },
    resetPage: state => {
      state.page = 1;
    },
    updatePostLikeStatus: (state, action) => {
      state.posts[action.payload.uid].liked = action.payload.liked;
      state.posts[action.payload.uid].likesCount = action.payload.likesCount;
    },
    removePosts: (state) => {
      state.posts = null;
      state.postIds = [];
      state.page = 1;
      state.totalPostCount = 0;
    },
    removePost: (state, action) => {
      let posts = state.posts;
      delete posts[action.payload.postId];
      state.posts = posts;
      state.postIds = state.postIds.filter(item => item !== action.payload.postId);
    },
    appendNextPagePosts: (state, action) => {
      state.posts = {
        ...state.posts,
        ...action.payload.posts
      }
      state.postIds = [...state.postIds, ...action.payload.postIds]
      state.totalPostCount = action.payload.totalPostCount ? action.payload.totalPostCount : state.totalPostCount;
    }
  }
});

export const { updatePosts, nextPage, updatePostLikeStatus, removePosts, resetPage, appendNextPagePosts, removePost } = homeFeedSlice.actions

export const getHomeFeed = () => (dispatch) => {
  dispatch(resetPage())
  const token = localStorage.getItem('lumieConsoleToken');
  axios.get(`${API_URL}/v1/posts?limit=5&page=${1}&sort=-created_at`, { headers: { "Authorization": `Bearer ${token}` } })
    .then((resp) => {
      console.log(resp)
      if (resp.data.count === 0) {
        dispatch(updatePosts(
          {
            posts: {},
            postIds: []
          })
        )
      } else {
        const postIds = resp.data.posts.map(post => post.uid);
        const posts = resp.data.posts.reduce((result, item) => {
          result[item.uid] = item;
          return result
        }, {})
        const totalPostCount = resp.data.count;
        dispatch(updatePosts(
          {
            posts,
            postIds,
            totalPostCount,
          }
        ))
      }
    }).catch((err) => {
      console.log(err)
    })
}

export const getNextPagePosts = () => (dispatch, getState) => {
  const page = getState().homeFeed.page;
  const token = localStorage.getItem('lumieConsoleToken');
  axios.get(`${API_URL}/v1/posts?limit=5&page=${page}&sort=-created_at`, { headers: { "Authorization": `Bearer ${token}` } })
    .then((resp) => {
      console.log(resp);
      const postIds = resp.data.posts.map(post => post.uid);
      const posts = resp.data.posts.reduce((result, item) => {
        result[item.uid] = item;
        return result
      }, {})
      const totalPostCount = resp.data.count;
      dispatch(appendNextPagePosts(
        {
          posts,
          postIds,
          totalPostCount,
        }
      ))
    }).catch((err) => {
      console.log(err)
    })
}

export const getNextPage = () => dispatch => {
  dispatch(nextPage())
  dispatch(getNextPagePosts())
}

export const updateLikes = (postId, likeStatus) => dispatch => {
  const token = localStorage.getItem('lumieConsoleToken');
  const method = likeStatus ? 'put' : 'delete';
  let url = `${API_URL}/v1/posts/${postId}/likes`
  axios({ method: method, url: url, data: {}, headers: { "Authorization": `Bearer ${token}` } })
    .then((resp) => {
      const { uid, likesCount, liked } = resp.data;
      dispatch(updatePostLikeStatus({ uid, likesCount, liked }))
    }).catch((err) => {
      console.log("Like err: ", err)
    })
}

export const deletePost = (postId, msg) => dispatch => {
  const token = localStorage.getItem('lumieConsoleToken');
  axios.delete(`${API_URL}/v1/posts/${postId}`, { headers: { "Authorization": `Bearer ${token}` } })
    .then(() => {
      dispatch(removePost({ postId }))
     message.success(msg)
    })
}

export default homeFeedSlice.reducer