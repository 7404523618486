import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { API_URL } from 'features/helper';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { motion } from "framer-motion";
import { useClickOutside } from "react-click-outside-hook";
import debounce from 'lodash.debounce';
import Loader from 'DesignSystem/Loader';
import UserList from './UserList';
import RecentSearches from './RecentSearches';
import { addUserSearchHistory } from 'features/userSlice';
import './index.scss';

const SearchBar = () => {
  const [isExpanded, setExpanded] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState([]);
  const [ref, isClickedOutside] = useClickOutside();
  const inputRef = useRef();
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();


  const searchUsers = () => {
    const token = localStorage.getItem('lumieConsoleToken');
    axios.get(`${API_URL}/v1/search?st[username,name]=${searchQuery}`, { headers: {"Authorization" : `Bearer ${token}`} })
    .then((results) => {
      setIsSearching(false);
      setResults(results.data.users);
      console.log("Results: ", results)
    });
  }
  const delayedQuery = useCallback(debounce(searchUsers, 500), [searchQuery]);

  useEffect(() => {
    if (searchQuery.length > 0) {
      delayedQuery();
    }
 
    // Cancel the debounce on useEffect cleanup.
    return delayedQuery.cancel;
 }, [searchQuery, delayedQuery]);

 useEffect(() => {
   if (!isExpanded) {
    setTimeout(() => {
      setSearchQuery('');
      setResults([]);
    }, 1000)
   }
 }, [isExpanded]);

  const expandContainer = () => {
    setExpanded(true);
  }

  const collapseContainer = () => {
    setExpanded(false);
    if (inputRef.current) {
      inputRef.current.value = ""
    }
  }

  const containerVariants = {
    expanded: {
      height: "20em",
    },
    collapsed: {
      height: "1.8em",
    }
  }

  useEffect(() => {
    if (isClickedOutside) {
      collapseContainer();
    }
  }, [isClickedOutside])

  const containerTransition = { type: 'spring', damping: 22, stiffness: 150}
  
  const handleUserClick = (user) => {
    collapseContainer();
    dispatch(addUserSearchHistory({username: user.username}))
  }

  const getUsers = () => {
    if (isSearching) {
      return (
        <div className="loader-container">
          <Loader />
        </div>
      )
    } else {
      if (results.length > 0) {
        return (
          <UserList users={results} handleUserClick={handleUserClick} />
        )
      } else {
        if (searchQuery.length > 0 && results.length === 0) {
          return (
            <div className="no-results-container">
              No Results Found
            </div>
          )
        } else {
          return (
            <RecentSearches collapseContainer={collapseContainer} />
          )
        }
      }
    }
  }

  const handleInputChange = (e) => {
    if (e.target.value.length > 0) {
      if (!isSearching) {
        setIsSearching(true)
      }
    } else {
      setResults([])
    }
    setSearchQuery(e.target.value)
  }

  return (
    <motion.div 
      animate={isExpanded ? "expanded" : "collapsed"}
      variants={containerVariants}
      ref={ref}
      transition={containerTransition}
      className="search-bar-container">
      <div className="search-input-container">
        <SearchOutlined style={{fontSize: '16px'}} />
        <input 
          ref={inputRef}
          onFocus={expandContainer}
          value={searchQuery}
          onChange={(e) => handleInputChange(e)}
          placeholder="Search for creators"
          className="search-input" />
        {/* <CloseOutlined style={{fontSize: '12px'}}/> */}
      </div>
      <div className="line-separator"></div>
      {getUsers()}
    </motion.div>
  )
}

export default SearchBar;
