const API_URL = process.env.REACT_APP_API_URL;
const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL;
const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    case "auth/wrong-password":
      return 'wrongPassword';
    case "auth/weak-password":
      return 'weakPassword';
    case "auth/user-not-found":
      return "emailNotFound";
    case "auth/email-already-in-use":
      return "emailExists";
    case "auth/requires-recent-login":
      return "Please authenticate again.";
    case "auth/too-many-requests":
      return "tooManyRequestError"
    default:
      return 'error'; 
  }
};
export { API_URL, PAYMENT_URL, STRIPE_KEY, getErrorMessage };