import { Button } from 'antd';
import GradientText from 'DesignSystem/GradientText';
import './index.scss';
export default function CustomButton({ text, Icon, style, type, ...rest }) {
  const getButton = () => {
    if (type.includes('primary-btn')) {
      return (
        <Button {...rest} style={{ ...style }} className={type} icon={Icon}>
          {text}
        </Button>
      )
    } else {
      return (
        <Button {...rest} style={{ ...style }} className={type} icon={Icon}>
          {text}
        </Button>
      )
    }
  }
  return (
      getButton()
  )
}