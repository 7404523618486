import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Signin from 'domain/Authentication/SignIn';
import SignUp from 'domain/Authentication/SignUp';
import ForgotPassword from 'domain/Authentication/ForgotPassword';
import { Modal } from 'antd';

export default function AuthenticationModal({ button, initialAuthScreen = 'login' }) {
  const user = useSelector((state) => state.user.userDetails);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeAuthScreen, setActiveAuthScreen] = useState('');


  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (user) {
      setIsModalVisible(false);
    }
  }, [user]);

  useEffect(() => {
    setActiveAuthScreen(initialAuthScreen);
  }, [initialAuthScreen])

  const getActiveAuthScreen = () => {
    if (activeAuthScreen === 'login') {
      return (
        <Signin popup={true} setActiveAuthScreen={setActiveAuthScreen}/>
      )
    } else if (activeAuthScreen === 'signup') {
      return (
        <SignUp popup={true} setActiveAuthScreen={setActiveAuthScreen}/>
      )
    } else if (activeAuthScreen === 'forgotPassword') {
      return (
        <ForgotPassword popup={true} setActiveAuthScreen={setActiveAuthScreen}/>
      )
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setActiveAuthScreen(initialAuthScreen);
  };

  return (
    <div>
      {React.cloneElement(button, { onClick: showModal })}
      <Modal title="" visible={isModalVisible} footer={null} onCancel={handleCancel}>
        {getActiveAuthScreen()}
      </Modal>
    </div>
  )
}
