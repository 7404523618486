import React from 'react';
import Sider from 'components/Sider';

export default function LeftSider({style, children}) {
  return (
    <Sider style={{...style}}>
      {children}
    </Sider>
  )
}
