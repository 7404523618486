import React from 'react'
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { authorizeUser, getUserDetails, updatingUserAction } from 'features/userSlice.js';
import { Route, useNavigate, Routes, useLocation, Navigate } from 'react-router-dom';
import loadable from '@loadable/component'
import { auth } from 'firebase.js';
import { onAuthStateChanged } from "firebase/auth";
import Sidebar from 'domain/Sidebar';
import LeftSider from 'components/LeftSider';
import VerifyEmail from 'domain/VerifyEmail';
import FooterAuthentication from 'components/FooterAuthentication/index.js';
import RedirectToLumie from 'components/RedirectToLumie';
import GlobalHeaderBar from 'components/GlobalHeaderBar';
import BottomNavBar from 'domain/BottomNavBar';
import MainLoader from 'components/MainLoader';
import usePageTracking from 'usePageTracking';

const Authentication = loadable(() => import('../Authentication/index.js'))

const ShowProfile = loadable(() => import('../Profile/ShowProfile'))
const EditProfile = loadable(() => import('../Profile/EditProfile'))
const HomeFeed = loadable(() => import('../HomeFeed'))
const Settings = loadable(() => import('../Settings'))
const CreatePost = loadable(() => import('../CreatePost'))
const EditPost = loadable(() => import('../EditPost'))
const ShowPost = loadable(() => import('../ShowPost'))
const FollowersFollowing = loadable(() => import('../FollowersFollowing'))
const EarningHistory = loadable(() => import('../EarningHistory'));
const PurchaseHistory = loadable(() => import('../PurchaseHistory'));
const MainContainer = loadable(() => import('components/MainContainer'));
const MobileSearch = loadable(() => import('../MobileSearch'));
const UnVerifiedEmail = loadable(() => import('components/UnverifiedEmail'));
function AuthProvider() {
  usePageTracking();
  const user = useSelector((state) => state.user.userDetails);
  const userAction = useSelector(state => state.user.userAction);
  const globalHeaderText = useSelector(state => state.ui.globalHeaderText);
  const location = useLocation();
  const fbUser = auth.currentUser;

  const [status, setStatus] = useState('pending');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  

  React.useEffect(() => {
    if (user) {
      const path = ['/login', '/signup', '/forgot-password']
      if (fbUser) {
        if (fbUser.emailVerified) {
          setStatus('success')
        } else {
          setStatus('unverifiedEmail');
        }
      }
      if (path.includes(location.pathname)) {
        if (user.emailVerified) {
          navigate('/home');
        } else {
          navigate('/unverified-email')
        }
      } else {
        navigate(location.pathname);
      }
    } else {
      if (userAction === 'logout') {
        navigate('/login')
        dispatch(updatingUserAction(''))
      }
    }
  }, [user, navigate, userAction, fbUser])

  React.useEffect(() => {
    onAuthStateChanged(auth, (firebaseUser) => {
      const authToken = localStorage.getItem('lumieConsoleToken')
      const tokenExpiration = localStorage.getItem('lumieConsoleTokenExpire');
      if (firebaseUser) {
        if (user === null) {
          if (authToken) {
            const currentTime = new Date();
            const tokenExpirationTime = new Date(tokenExpiration);
            if (currentTime > tokenExpirationTime) {
              dispatch(authorizeUser({ idToken: firebaseUser.accessToken }))
            } else {
              dispatch(getUserDetails());
            }
          } else {
            dispatch(authorizeUser({ idToken: firebaseUser.accessToken }))
          }
        }
      } else {
        if (user === null) {
          if (authToken) {
            const currentTime = new Date();
            const tokenExpirationTime = new Date(tokenExpiration);
            if (currentTime > tokenExpirationTime) {
              localStorage.removeItem('lumieConsoleToken')
              localStorage.removeItem('lumieConsoleTokenExpire')
              navigate('/login')
              setStatus('showLogin')
            } else {
              dispatch(getUserDetails());
            }
          } else {
            setStatus('showLogin')
          }
        }
      }
    })
  }, [user, dispatch])

  const unAuthenticatedRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<RedirectToLumie />} />
        <Route path="/login" element={<Authentication page={'login'} />} />
        <Route path="/signup" element={<Authentication page={'signup'} />} />
        <Route path="/forgot-password" element={<Authentication page={'forgotPassword'} />} />
        <Route path="/home" element={<Navigate to="/login" />} />
        <Route path="settings/*" element={<Navigate to="/login" />} />
        <Route path="/post" element={<Navigate to="/login" />} />
        <Route path="/purchases" element={<Navigate to="/login" />} />
        <Route path="/earnings-and-payouts" element={<Navigate to="/login" />} />
        <Route path="edit/*" element={<Navigate to="/login" />} />
        <Route path="edit-post/*" element={<Navigate to="/login" />} />
        <Route path=":slug/edit" element={<Navigate to="/login" />} />
        <Route path=":slug/followers" element={<Navigate to="/login" />} />
        <Route path=":slug/following" element={<Navigate to="/login" />} />
        <Route path="/:slug" element={<ShowProfile />} />
        <Route path=":username/post/:postId" element={<ShowPost />} />
        <Route path="/unverified-email" element={<Navigate to="/login" />} />
      </Routes>
    )
  }

  const getMobileSearchRoute = () => {
    if (window.screen.width < 768) {
      return (
        <Route path="search" element={<MobileSearch />} />
      )
    }
  }

  const authenticatedRoutes = () => {
    return (
      <Routes>
        <Route path="" element={<Navigate to="/home" />} />
        <Route path="home" element={<HomeFeed />} />
        {getMobileSearchRoute()}
        <Route path=":slug" element={<ShowProfile />} />
        <Route path=":slug/edit" element={<EditProfile />} />
        <Route path="settings/*" element={<Settings />} />
        <Route path=":slug/followers" element={<FollowersFollowing />} />
        <Route path=":slug/following" element={<FollowersFollowing />} />
        <Route path="post" element={<CreatePost />} />
        <Route path="edit-post/:postId" element={<EditPost />} />
        <Route path=":username/post/:postId" element={<ShowPost />} />
        <Route path="earnings-and-payouts" element={<EarningHistory />} />
        <Route path="purchases" element={<PurchaseHistory />} />
        <Route path="/login" element={<Navigate to="/home" />} />
        <Route path="/signup" element={<Navigate to="/home" />} />
        <Route path="/forgot-password" element={<Navigate to="/home" />} />
        <Route path="/unverified-email" element={<Navigate to="/home" />} />
      </Routes>
    )
  }

  const unverifiedEmailRoute = () => {
    return (
      <Routes>
        <Route path="" element={<Navigate to="/unverified-email" />} />
        <Route path="/login" element={<Navigate to="/unverified-email" />} />
        <Route path="/signup" element={<Navigate to="/unverified-email" />} />
        <Route path="/unverified-email" element={<UnVerifiedEmail page={'unverifiedEmail'} />} />
        <Route path="/home" element={<Navigate to="/unverified-email" />} />
        <Route path="/forgot-password" element={<Authentication page={'forgotPassword'} />} />
        <Route path="/*" element={<Navigate to="/unverified-email" />} />
      </Routes>
    )
  }

  const getRoutes = () => {
    if (status === 'pending') {
      return (
        <MainLoader />
      )
    } else if (status === 'showLogin') {
      return (
        <>
          {unAuthenticatedRoutes()}
        </>
      )
    } else if (fbUser && status === 'success') {
      return (
        <>
          {authenticatedRoutes()}
        </>
      )
    } else if (status === 'unverifiedEmail') {
      return (
        <>
          {unverifiedEmailRoute()}
        </>
      )
    }
  }

  const getFooterAuthentication = () => {
    if (user === null && auth.currentUser === null && status !== 'pending') {
      const path = ['/login', '/signup', '/forgot-password']
      if (!path.includes(location.pathname)) {
        return <FooterAuthentication />
      }
    }
    return <></>
  }

  const getLayout = () => {
    const path = ['/login', '/signup', '/forgot-password', '/unverified-email']
    document.body.classList.add('defaultBody');
    if (path.includes(location.pathname)) {
      document.body.classList.remove('defaultBody');
      return getRoutes()
    }
    return (
      <div>
        {getFooterAuthentication()}
        <GlobalHeaderBar user={user} text={globalHeaderText} />
        <VerifyEmail />
        <BottomNavBar />
        <div className="app" style={{ display: 'flex' }}>
          <LeftSider style={{ flex: 0.20, margin: '16px 16px 16px 0px' }}>
            <Sidebar />
          </LeftSider>
          <MainContainer>
            {getRoutes()}
          </MainContainer>
        </div>
      </div>
    )
  }
  return (
    <>
      {getLayout()}
    </>
  )

}

export default AuthProvider;
