import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { deleteAllUserSearchHistory, deleteSearch } from 'features/userSlice';
import Loader from 'DesignSystem/Loader';
import ProfilePhoto from 'components/ProfilePhoto';
import { CloseOutlined } from '@ant-design/icons';
import './index.scss'

const RecentSearches = ({collapseContainer}) => {
  const userSearchHistory = useSelector((state) => state.user.userSearchHistory);
  const [actionPending, setActionPending] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteAllSearchHistory = () => {
    setActionPending(true);
    dispatch(deleteAllUserSearchHistory());
    setActionPending(false);
  }

  const handleDeleteSearch = (e, user) => {
    e.stopPropagation();
    setActionPending(true);
    dispatch(deleteSearch(user.username));
    setActionPending(false);
  }

  const handleNavigation = (username) => {
    navigate(`/${username}`)
    collapseContainer();
  }

  const getUsers = () => {
    if (userSearchHistory.length > 0) {
      const usersList = userSearchHistory.map((user) => {
        return (
          <div onClick={() => handleNavigation(user.username)} className="user-container">
            <div className="user-profile-avatar">
              <ProfilePhoto
                photoUrl={user.photoUrl}
                username={user.username}
                size={34}
                style={{ fontSize: '14px' }}
              />
            </div>
            <div className="name-container">
              <div className="username">{user.username}</div>
              <div className="name">{user.name}</div>
            </div>
            <div className="remove-container">
              <CloseOutlined onClick={(e) => handleDeleteSearch(e, user)} className="remove-icon" style={{padding: '5px'}} />
            </div>
          </div>
        )
      })
      return (
        <div className="user-list-container">{usersList}</div>
      )
    }
    return <></>
  }

  const getRecentSearches = () => {
    if (userSearchHistory.length > 0) {
      return (
        <div className="search-header">
          <div className="title">Recent Searches</div>
          <div onClick={() => handleDeleteAllSearchHistory()} className="action">Clear All</div>
          
        </div>
      )
    }
    return (
      <div className="no-search-container">
        No Recent Searches
      </div>
    )
  }

  const getOverlayLoader = () => {
    if (actionPending) {
      return (
        <div className="overlay-container">
          <Loader />
        </div>
      )
    }
  }

  return (
    <div className="user-search-history-container">
      {getRecentSearches()}
      {getUsers()}
      {getOverlayLoader()}
    </div>
  )
}

export default RecentSearches;