import React from 'react';
import './index.scss';

export default function Sider({style, children}) {
  return (
    <div style={{...style}} className="sider-container">
      {children}
    </div>
  )
}
