import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import SearchBar from 'domain/SearchBar';
import LanguageSwitcher from 'components/LanguageSwitcher';
import lumieIcon from 'resources/images/94hive-logo.png';
import './index.scss';

export default function GlobalHeaderBar({ user, text, path, ...rest }) {
  const navigate = useNavigate();
  const location = useLocation();

  const getTextContainer = () => {
    if (path) {
      return (
        <>
          <div onClick={() => navigate(path)} className="icon"><ArrowLeftOutlined /></div>
          <div className="text">{text}</div>
        </>
      )
    } else {
      return (
        <div className="text">{text}</div>
      )
    }
  }



  const getHeaderWithSearch = () => {
    if (location.pathname === '/search') {
      return (
        <div {...rest} className="global-header-bar">
          {user
            ? <SearchBar />
            : <LanguageSwitcher />
          }
        </div>
      )
    } else {
      if (window.screen.width < 768) {
        return (
          <div {...rest} className="global-header-bar">
          <img src={lumieIcon} className="sidebar__logo" alt="logo" onClick={() => navigate('/home')} />
          {getTextContainer()}
          {user 
            ? <Link to="/search" className="mobileSearch" style={{color: 'black'}}><SearchOutlined /></Link>
            : <LanguageSwitcher />
          }
        </div>
        )
      }
      return (
        <div {...rest} className="global-header-bar">
        <img src={lumieIcon} className="sidebar__logo" alt="logo" onClick={() => navigate('/home')} />
        {getTextContainer()}
          {user && user.emailVerified
              ? <SearchBar />
              : <LanguageSwitcher />
          }
      </div>
      )
    }
  }
  return (
    getHeaderWithSearch()
  )
}