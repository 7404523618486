import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { auth } from 'firebase.js';
import SidebarOption from './SidebarOption';
import { HomeOutlined,
  SettingOutlined,
  UserOutlined,
  PlusOutlined,
  DollarOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import Button from 'DesignSystem/Button';
import { useTranslation } from 'react-i18next';
import './index.scss';

export default function Sidebar() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userDetails);
  const navigate = useNavigate();
  const firebaseUser = auth.currentUser;

  const getSidebar = () => {
    if (user && firebaseUser) {
      const userName = user.username;
      return (
        <div className="sidebar">
          <SidebarOption text={t("home")} Icon={HomeOutlined} path="/home" parentPath="home" />
          <SidebarOption text={t("myProfile")} Icon={UserOutlined} path={`/${userName}`} parentPath={`${userName}`}/>
          <SidebarOption text={t("earnings")} Icon={WalletOutlined} path="/earnings-and-payouts" parentPath="earnings-and-payouts"/>
          <SidebarOption text={t("purchases")} Icon={DollarOutlined} path="/purchases" parentPath="purchases"/>
          <SidebarOption text={t("settings")} Icon={SettingOutlined} path="/settings/account" parentPath="settings"/>
          
          <Button
            onClick={() => navigate('/post')}
            style={{ height: '48px', width: '100%'}} 
            text={t("newPost")}
            Icon={<PlusOutlined />} 
            type="primary-btn"
          />
        </div>
      )
    } else {
      return <div className="sidebar"></div>
    }
  }
  return (
    getSidebar()
  )
}
