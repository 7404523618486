import React from 'react';
import Loader from 'DesignSystem/Loader';
import './index.scss';

export default function MainLoader(props) {
  return (
    <div className="main-loader">
      <Loader size={40} />
    </div>
  )
}
