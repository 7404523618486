import React, { useEffect } from 'react'

export default function RedirectToLumie() {

  useEffect(() => {
    window.location.href = "https://www.94hive.cc/";
  }, []);

  return (
    <div>
      
    </div>
  )
}
