import { configureStore } from '@reduxjs/toolkit';
import userReducer from 'features/userSlice';
import profileReducer from 'features/profileSlice';
import homeFeedReducer from 'features/homeFeedSlice';
import uiReducer from 'features/uiSlice';
import { logger } from 'redux-logger';

export default configureStore({
  reducer: {
    user: userReducer,
    profile: profileReducer,
    homeFeed: homeFeedReducer,
    ui: uiReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});