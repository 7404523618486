import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_ZH } from "./zh/translations";
import { TRANSLATIONS_EN } from "./en/translations";
 
i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   fallbackLng: 'zh',
   resources: {
     zh: {
       translation: TRANSLATIONS_ZH
     },
     en: {
       translation: TRANSLATIONS_EN
     },
   },
   detection: {
    order: ['cookie', 'navigator', 'htmlTag', 'localStorage', 'sessionStorage', 'path', 'subdomain'],
    caches: ['cookie', 'localStorage']
   }
 });