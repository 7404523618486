import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth } from 'firebase.js';
import BottomBarOption from './BottomBarOption';
import { HomeOutlined,
  SettingOutlined,
  UserOutlined,
  PlusOutlined,
  DollarOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import './index.scss';

export default function BottomNavBar() {
  const user = useSelector((state) => state.user.userDetails);
  const firebaseUser = auth.currentUser;

  const getSidebar = () => {
    if (user && firebaseUser) {
      const userName = user.username;
      return (
        <div className="bottom-bar">
          <BottomBarOption Icon={HomeOutlined} path="/home" parentPath="home" />
          <BottomBarOption Icon={UserOutlined} path={`/${userName}`} parentPath={`${userName}`}/>
          <BottomBarOption Icon={WalletOutlined} path="/earnings-and-payouts" parentPath="earnings-and-payouts"/>
          <BottomBarOption Icon={DollarOutlined} path="/purchases" parentPath="purchases"/>
          <BottomBarOption Icon={SettingOutlined} path="/settings" parentPath="settings"/>
          <div className="new-post-container">
            <Link className="new-post-link" to={"/post"}>
              <>
                <PlusOutlined />
              </>
            </Link>
          </div>
        </div>
      )
    } else {
      return <></>
    }
  }
  return (
    getSidebar()
  )
}
