import React from 'react';
import { useNavigate } from "react-router-dom";
import ForgotPasswordForm from 'domain/Authentication/ForgotPasswordForm';
import GradientText from 'DesignSystem/GradientText';
import { useTranslation } from "react-i18next";
import LumieLogo from 'resources/images/94hive-logo.png';
import './index.scss'


function ForgotPassword(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getRedirectionBlock = () => {
    if (props.popup) {
      return (
        <div className="redirection">
          {t('backTo')} <span onClick={() => {props.setActiveAuthScreen('login')}}><GradientText>{t('signin')}</GradientText></span>
        </div>
      )
    } else if (props.settings) {
      return (
        <></>
      )
    } else {
      return (
        <div className="redirection">
          {t('backTo')} <span onClick={() => {navigate('/login')}}><GradientText>{t('signin')}</GradientText></span>
        </div>
      )
    }
  }

  const getLogo = () => {
    if (props.settings) {
      return <></>
    }
    return <img src={LumieLogo} alt="lumie-logo" />
  }

  return (
    <div className="authentication-container">
      <div className="header">
        {getLogo()}
        {t('resetPassword')}
      </div>
      <ForgotPasswordForm />
      {getRedirectionBlock()}
    </div>
  );
}

export default ForgotPassword;